import React, { useEffect } from 'react';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Box from '@nubank/nuds-web/components/Box/Box';
import Image from '@nubank/nuds-web/components/Image/Image';
import styled from 'styled-components';
import Link from '@nubank/nuds-web/components/Link/Link';

import { useSiteContext } from '../../../../../components/SiteContext/SiteContext';
import { FormStepContainer } from '../../../styles/FormStepContainer';
import { redirectionEvent } from '../../../tracking';
import { useRegistrationFormContext } from '../../RegistrationForm/RegistrationForm';
import { generateBranchLink } from '../../../../../utils/branchIO/generateBranchLink';

const StyledTitle = styled(Typography)`
  font-size: 28px;

  @media (width >= 768px) {
    font-size: 2.1rem;
  }
`;

const Line = styled(Box)`
  border: 1px solid rgba(17 17 17 / 10%);
  margin-top: 5px;
  width: 97%;
`;

const StyledBox = styled(Box)`
  gap: 8px;
  
  p {
    flex: 1;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
`;

function SecuredCardRedirection() {
  const { email, phone } = useSiteContext();
  const { prospectRegistrationData } = useRegistrationFormContext();

  useEffect(() => {
    redirectionEvent('rtr sec');
  }, []);

  const navigateToMagicLink = () => {
    window.open(generateBranchLink({ prospectId: prospectRegistrationData.prospectId }), '_blank'); // Opens in a new tab
  };

  return (
    <FormStepContainer height="100%">
      {/* TITLE AND SUBTITLE */}
      <StyledTitle
        variant="heading2"
        marginTop="-25px"
      >
        ¡Ya tenemos tu oferta lista en la app! 🥳️
      </StyledTitle>

      <Typography
        variant="subtitle1"
        marginTop="4x"
        marginBottom="6x"
        whiteSpace="pre-line"
        strong
      >
        <Typography strong tag="span" variant="subtitle1" color="primary">
          Continúa
        </Typography>
        {' para terminar de contratar tu oferta.'}
      </Typography>

      <Typography color="#000000A3" variant="subtitle2">
        {'Si ya tienes la app continúa el registro con el correo '}
        <Typography tag="span" color="primary" strong variant="subtitle2">
          {email }
        </Typography>
        {' y el celular '}
        <Typography tag="span" color="primary" strong variant="subtitle2">
          {`** ****${phone.slice(8)}`}
        </Typography>
        .
      </Typography>

      {/* MAGIC LINK BUTTON */}

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom="4x"
        marginTop="8x"
      >
        <StyledButton
          iconProps={{ name: 'arrow-right' }}
          extended
          type="button"
          onClick={() => navigateToMagicLink()}
        >
          Continuar en la app
        </StyledButton>
      </Box>

      {/* IMAGE BLOCK */}
      <Typography variant="subtitle1" strong>
        Tu oferta te está esperando:
      </Typography>

      <StyledBox
        marginTop="6x"
        marginBottom="6x"
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Image
          alt="Icono de una tarjeta"
          src="one-step/rtr/secured-card-magic.png"
          webp
          width="98px"
          height="99px"
        />

        <Typography variant="paragraph1">
          <Typography variant="paragraph1" strong tag="span">
            Tarjeta Garantizada y
            Cuenta Nu
          </Typography>
          {' '}
          para que tu
          historial crediticio tenga
          una nueva oportunidad
        </Typography>
      </StyledBox>

      {/* BENEFITS LIST */}

      <Box marginBottom="4x" marginTop="2x">
        <Typography variant="heading4" strong color="primary">
          Qué beneficios te da Nu:
        </Typography>

        <Line tag="hr" />

        <Box
          marginTop="6x"
          display="flex"
          flexDirection="row"
        >
          <Image
            alt="Icono de carita feliz"
            src="one-step/rtr/smiley.svg"
            webp={false}
            width="24px"
          />
          <Typography
            variant="paragraph1"
            marginLeft="4x"
            marginTop="-2px"
            color="#000000A3"
          >
            Atención
            <Typography
              tag="span"
              strong
              variant="paragraph1"
              color="black"
            >
              {' '}
              humana y soporte las 24/7.
            </Typography>
          </Typography>
        </Box>

        <Box
          marginTop="6x"
          display="flex"
          flexDirection="row"
        >
          <Image
            alt="Icono de tarjetas"
            src="one-step/rtr/cards.svg"
            webp={false}
            width="40px"
          />
          <Typography
            variant="paragraph1"
            marginLeft="4x"
            marginTop="-4px"
            color="#000000A3"
          >
            El buen uso de tu tarjeta te ayuda a tener
            <Typography
              color="black"
              tag="span"
              strong
              variant="paragraph1"
            >
              {' '}
              mejores reportes a buró de crédito.
              {' '}
            </Typography>
          </Typography>
        </Box>

        <Box
          marginTop="6x"
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Image
            alt="Icono de corazón"
            src="one-step/rtr/heart.svg"
            webp={false}
            width="24px"
          />
          <Typography
            variant="paragraph1"
            marginLeft="4x"
            marginTop="-5px"
            strong
          >
            $0 anualidad

            <Typography
              color="#000000A3"
              variant="paragraph1"
              tag="span"
            >
              {' '}
              ni comisiones por uso mínimo.
            </Typography>
          </Typography>
        </Box>

        <Box
          marginTop="6x"
          display="flex"
          flexDirection="row"
        >
          <Image
            alt="Icono de escudo"
            src="one-step/rtr/shield.svg"
            webp={false}
            width="36px"
          />
          <Typography
            variant="paragraph1"
            marginLeft="4x"
            color="#000000A3"
          >
            Tecnología para
            <Typography
              tag="span"
              strong
              variant="paragraph1"
              color="black"
            >
              {' '}
              cuidar que solo tú apruebes
              {' '}
            </Typography>
            y sepas de cada transacción.
          </Typography>
        </Box>

      </Box>

      {/* CAT LINK */}

      <Box
        display="flex"
        justifyContent="center"
        marginTop="12x"
      >
        <Link
          href="/gat"
          variant="action"
          typographyProps={{ variant: 'paragraph1' }}
          target="_blank"
        >
          Conoce GAT Real y Nominal de Cuenta
        </Link>
      </Box>

    </FormStepContainer>
  );
}

export default SecuredCardRedirection;
