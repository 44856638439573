import React, { useEffect } from 'react';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Image from '@nubank/nuds-web/components/Image/Image';
import Box from '@nubank/nuds-web/components/Box/Box';

import { useSiteContext } from '../../../../../components/SiteContext/SiteContext';
import { redirectionEvent } from '../../../tracking';
import { DeniedScreenContainer } from '../../DecisionStep/styles/DeniedScreenContainer';

const DeniedRedirection = () => {
  const { email, closeRegistrationForm } = useSiteContext();

  const handleCloseBtn = () => {
    closeRegistrationForm(true);
  };

  useEffect(() => {
    redirectionEvent('rtr denied');
  }, []);

  return (
    <DeniedScreenContainer>
      <Image
        src="one-step/card-wait-notice.svg"
        alt="Un icono de un sobre de mensaje morado"
        webp={false}
        width="150px"
      />

      <Typography gutterBottom={1} variant="heading2">
        ¡Listo!
      </Typography>

      <Typography gutterBottom={2} variant="subtitle1">
        Recibimos tu información. Enviaremos una respuesta al correo electrónico
        <Typography tag="span" variant="subtitle1" strong>
          {' '}
          {email}
          {' '}
        </Typography>
        en las próximas 72 horas.
      </Typography>

      <Box marginTop="auto">
        <Button
          variant="contained"
          styleVariant="primary"
          extended
          onClick={handleCloseBtn}
          intlKey="PROSPECT_REGISTRATION_FORM.DENIED_SCREEN.FINISH_BUTTON_LABEL"
        />
      </Box>

    </DeniedScreenContainer>
  );
};

export default DeniedRedirection;
