import React from 'react';
import Form from '@nubank/nuds-web/components/Form/Form';

import { useRegistrationFormContext } from '../RegistrationForm/RegistrationForm';

import DeniedRedirection from './rtr/DeniedRedirection';
import SecuredCardRedirection from './rtr/SecuredCardRedirection';
import MultiproductRedirection from './rtr/MultiproductRedirection';
import CuentaOnlyRedirection from './rtr/CuentaRedirection';

const RTRScreen = () => {
  const { prospectRegistrationData } = useRegistrationFormContext();
  return (
    <Form.Step isForm={false}>
      {() => {
      // ///////////////////////////// MULTIPRODUCT ////////////////////////////////////////
        if (prospectRegistrationData.approvedProducts === 'credit_card_and_nuconta') {
          return <MultiproductRedirection />;
        }

        /// ////////////////////////// SECURED CARD////////////////////////////////////////
        if (prospectRegistrationData.approvedProducts === 'secured_credit_card_and_nuconta') {
          return <SecuredCardRedirection />;
        }

        /// ////////////////////////// CUENTA ////////////////////////////////////////
        if (prospectRegistrationData.approvedProducts === 'savings_account') {
          return <CuentaOnlyRedirection />;
        }

        // ////////////////////////// OTHER ////////////////////////////////////////
        return (
          <DeniedRedirection />
        );
      }}
    </Form.Step>
  );
};

export default RTRScreen;
